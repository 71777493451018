<template>
  <div class="hidden lg:block">
    <ul class="flex items-center gap-x-4 me-10">
      <li v-for="(item, itemIndex) in items" :key="itemIndex">
        <component
          :is="item.component"
          v-bind="item.bindOptions"
          class="text-blue-800 hover:text-blue-500 py-2 px-1"
        >
          <b>{{ item.title }}</b>
        </component>
      </li>
    </ul>
  </div>

  <HaDropdown
    v-model="showMenu"
    divider
    :items="items"
    :menu="{ class: 'w-44', follower: { offset: 9 } }"
  >
    <template #activator="{ props: dropdownProps, on: dropdownOn }">
      <HaButton
        class="!me-2 lg:hidden"
        color="default"
        size="32"
        v-bind="{
          ...dropdownProps,
          circle: true,
          icon: {
            icon: mdiMenu,
            size: 24
          }
        }"
        v-on="{
          ...dropdownOn
        }"
      />
    </template>
  </HaDropdown>
</template>

<script lang="ts" setup>
import { mdiMenu } from '@mdi/js'
import { HaButton, HaDropdown, IDropdownItem } from '@payiano/ha-atoms'
import { domains } from '@/constants'

const showMenu = ref(false)

const items: IDropdownItem[] = [
  {
    title: 'Docs',
    component: 'RouterLink',
    bindOptions: {
      to: '/api/rest/get-started'
    }
  },
  // {
  //   title: 'Partners',
  //   component: 'RouterLink',
  //   bindOptions: {
  //     to: '/api/rest/get-started'
  //   }
  // },
  {
    title: 'Support',
    component: 'a',
    bindOptions: {
      href: `${domains.payiano}/contact`,
      target: '_blank'
    }
  }
]
</script>
