<template>
  <header class="fixed top-0 start-0 z-[101] w-full bg-white shadow-md">
    <nav class="flex items-center justify-between h-full px-2 md:px-3">
      <div
        class="flex items-center flex-row-reverse lg:flex-row gap-x-2 lg:gap-x-14"
      >
        <router-link to="/" aria-label="Payiano Logo">
          <HaIcon
            :icon="PayianoEnglishSideBlueIcon"
            class="h-[20px] lg:h-[26px]"
          />
        </router-link>

        <HaButton
          v-if="hasDrawer"
          circle
          size="34"
          color="default"
          :icon="{
            size: 24,
            class: 'rtl:rotate-180',
            icon: showDrawer ? mdiMenuOpen : mdiMenuClose
          }"
          @click.stop="showDrawer = !showDrawer"
        />
      </div>

      <div class="flex items-center">
        <AppHeaderNavigation />

        <ClientOnly>
          <AppHeaderUserLogin />
        </ClientOnly>
      </div>
    </nav>
  </header>
</template>

<script lang="ts" setup>
import { mdiMenuClose, mdiMenuOpen } from '@mdi/js'
import { PayianoEnglishSideBlueIcon } from '@payiano/hc-icons'
import { HaButton, HaIcon } from '@payiano/ha-atoms'
import { useDisplay, useLocaleOrValue } from '@payiano/ha-composables'
import AppHeaderNavigation from './HeaderNavigation.vue'
import AppHeaderUserLogin from './HeaderUserLogin.vue'

const emit = defineEmits<{
  (e: 'update:showDrawer', value?: boolean): void
}>()

const { $router } = useNuxtApp()
const { lgAndDown } = useDisplay()

const props = defineProps<{
  hasDrawer?: boolean
  showDrawer?: boolean
}>()

const showDrawer = useLocaleOrValue({
  value: () => props.showDrawer,
  formatter: (value) => !!value,
  onUpdate: (value) => emit('update:showDrawer', value)
})

watch(
  () => $router.currentRoute.value.name,
  () => {
    if (lgAndDown.value && showDrawer.value) {
      showDrawer.value = false
    }
  }
)
</script>
