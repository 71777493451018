<template>
  <div class="de--login-accounts">
    <div
      v-for="(account, accountIndex) in accounts"
      :key="accountIndex"
      role="button"
      :class="[
        'de--login-account',
        {
          'de--login-account-active': localValue === account.type,
          'de--login-account-disabled': disabled
        }
      ]"
      @click="onClickSwitch(account)"
    >
      <div class="de--login-account-content">
        {{ account.title }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLocaleOrValue } from '@payiano/ha-composables'
import { Auth } from '@/types'

const emit = defineEmits<{
  (e: 'update:modelValue', value: Auth.Account): void
}>()

const props = defineProps<{
  accounts: Auth.IAccount[]
  modelValue?: Auth.Account
  disabled?: boolean
}>()

const localValue = useLocaleOrValue<Auth.Account>({
  value: () => props.modelValue,
  formatter: (value) => value ?? props.accounts[0]?.type,
  onUpdate: (value) => emit('update:modelValue', value)
})

const onClickSwitch = (account: Auth.IAccount) => {
  if (!props.disabled) {
    localValue.value = account.type
  }
}
</script>

<style lang="scss">
.de--login {
  &-accounts {
    @apply flex gap-x-2 md:gap-x-3 border-b;

    > div {
      @apply border-x border-t border-gray-200/80 rounded-t-sm lg:rounded-t-md px-3 py-2;
      @apply flex items-center text-sm md:text-lg text-gray-700 font-bold relative top-[1px];
    }
  }

  &-account {
    &-active {
      @apply bg-white;
    }

    &-disabled {
      @apply cursor-not-allowed;
    }

    &-content {
      @apply select-none;
    }

    &:not(&-active) &-content {
      @apply opacity-70;
    }
  }
}
</style>
