<template>
  <HaModal v-model="$login.showModal" v-bind="modalOptions">
    <AppHeaderUserLoginModalTabs
      v-model="$login.accountType"
      :accounts="loginAccounts"
      :disabled="loading"
      class="mb-6"
    />

    <div v-if="activeAccount" class="px-1">
      <HaForm
        v-model="data"
        v-model:loading="loading"
        v-bind="formOptions"
        class="mb-6"
      />

      <!-- TODO: comment out social media login -->

      <!-- <HaActions class="py-6" v-bind="socialAuthOptions" /> -->

      <div class="flex justify-between text-gray-700 text-sm">
        <a
          v-if="activeAccount.resetPasswordUrl"
          target="_blank"
          :href="activeAccount.resetPasswordUrl"
          class="underline"
        >
          <HaIcon size="20" :icon="mdiLock" />

          <span class="px-1">
            {{ t('auth.password.forgotYourPassword') }}
          </span>
        </a>

        <div v-if="activeAccount.registerUrl">
          <span class="me-1 hidden md:inline-block">
            Don't have an account ?
          </span>

          <a
            target="_blank"
            :href="activeAccount.registerUrl"
            class="text-gray-700 underline"
          >
            {{ t('auth.registerHere') }}
          </a>
        </div>
      </div>
    </div>
  </HaModal>
</template>

<script lang="ts" setup>
// import { AxiosResponse } from 'axios'
import { mdiLock, mdiLogin } from '@mdi/js'
// import {
//   SocialFacebookIcon,
//   SocialGithubIcon,
//   SocialGoogleIcon
// } from '@payiano/hc-icons'
import {
  HaIcon
  // IIcon
} from '@payiano/ha-atoms'
import { Form, HaForm, HaTextField, HaPasswordField } from '@payiano/ha-form'
import {
  // Actions, HaActions,
  HaModal,
  IModal
} from '@payiano/ha-molecules'
import {
  checkCallback
  // ucfirst
} from '@payiano/ha-utils'
import AppHeaderUserLoginModalTabs from './HeaderUserLoginModalTabs.vue'
import { Auth } from '@/types'

const { t } = useI18n()
const config = useRuntimeConfig()
const { $httpFn, $login } = useNuxtApp()

const props = defineProps<{
  accounts: Auth.IAccount[]
}>()

const loginAccounts = computed(() =>
  props.accounts.filter(({ loggedIn }) => !loggedIn)
)

const activeAccount = computed(() =>
  props.accounts.find(
    ({ type }) => $login.accountType && type === $login.accountType
  )
)

const apiBaseUrl = computed(() =>
  activeAccount.value ? useApiBaseUrl(activeAccount.value.domain).value : ''
)

const loading = ref(false)

const modalOptions = computed<IModal>(() => ({
  maxWidth: 600,
  persistent: loading.value,
  header: {
    icon: mdiLogin,
    title: 'Login to your Sandbox account',
    subtitle: 'Login and try out our extensive APIs.'
  }
}))

const data = ref(
  config.public.isProduction
    ? {}
    : {
        // TODO: to be removed!
        email: 'info@payiano.com',
        password: 'Powerfu1Pa$$'
      }
)

const formOptions = computed<
  Form.IProps<typeof data.value, Auth.ILoginResponse>
>(() => ({
  keepSuccessLoading: true,
  captcha: config.public.isProduction
    ? {
        apiKey: config.public.google.captchaKey,
        actionName: `developer_${activeAccount.value?.type}_login`
      }
    : undefined,
  submitAction: {
    button: { text: t('auth.login') }
  },
  request: $httpFn({
    url: '/login',
    baseURL: apiBaseUrl.value
  }),
  fields: [
    {
      key: 'email',
      label: t('user.email'),
      component: HaTextField,
      rules: 'required|min:5|max:255|email',
      bindOptions: {
        type: 'email'
      }
    },
    {
      key: 'password',
      label: t('user.password'),
      component: HaPasswordField,
      rules: 'required'
    }
  ],
  onSuccess: async (res, { reset }) => {
    await checkCallback(activeAccount.value?.onSuccessLogin, [res])

    $login.closeModal()

    loading.value = false

    reset()
  }
}))

// const providers: Record<string, IIcon> = {
//   facebook: SocialFacebookIcon,
//   google: SocialGoogleIcon,
//   github: SocialGithubIcon
// }

// const socialAuthOptions = computed<Actions.IProps>(() => ({
//   disabled: loading.value,
//   keepSuccessLoading: true,
//   disableOthersWhileLoading: true,
//   items: Object.entries(providers).map(([provider, icon]) => ({
//     prevent: true,
//     button: {
//       circle: true,
//       color: 'default',
//       icon: {
//         icon,
//         size: 26
//       }
//     },
//     tooltip: {
//       title: t('auth.loginWith', {
//         provider: ucfirst(provider)
//       })
//     },
//     request: $httpFn({
//       url: `/login/social/${provider}`,
//       baseURL: apiBaseUrl.value
//     }),
//     onSuccess: ({
//       data: { redirect_url }
//     }: AxiosResponse<{ redirect_url: string }>) =>
//       (location.href = redirect_url)
//   }))
// }))
</script>
