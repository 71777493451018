<template>
  <div>
    <AppHeaderUserDropdown
      v-if="firstLoggedIn"
      :accounts="accounts"
      :first-logged-in="firstLoggedIn"
    />
    <template v-else>
      <HaButton
        class="lg:hidden"
        circle
        color="default"
        :size="32"
        v-bind="buttonOptions"
      />

      <HaButton
        class="hidden lg:block"
        text="Login to Sandbox"
        color="secondary"
        v-bind="buttonOptions"
      />
    </template>

    <AppHeaderUserLoginModal :accounts="accounts" />
  </div>
</template>

<script lang="ts" setup>
import { mdiAccountCircle, mdiLogin, mdiPower } from '@mdi/js'
import { HaButton, IButton } from '@payiano/ha-atoms'
import AppHeaderUserDropdown from './HeaderUserDropdown.vue'
import AppHeaderUserLoginModal from './HeaderUserLoginModal.vue'
import { Auth } from '@/types'

const individualPortal = usePortalUrl('INDIVIDUAL')
const partnerPortal = usePortalUrl('PARTNER')
const adminPortal = usePortalUrl('ADMIN')
const { $login, $individual, $partnerEmployee, $payianoEmployee } = useNuxtApp()

const accounts = computed<Auth.IAccount[]>(() => [
  {
    title: 'Individual',
    type: 'INDIVIDUAL',
    domain: 'INDIVIDUAL',
    loggedIn: $individual.loggedIn,
    user: {
      id: $individual.user.id,
      name: $individual.user.name,
      email: $individual.user.email,
      avatar: $individual.user.avatar
    },
    actions: [
      {
        icon: mdiAccountCircle,
        text: 'Profile',
        component: 'a',
        bindOptions: {
          target: '_blank',
          href: `${individualPortal.sandboxPortalUrl.value}/profile`
        }
      },
      {
        icon: mdiPower,
        text: 'Logout',
        on: {
          click: () => $individual.logout()
        }
      }
    ],
    registerUrl: `${individualPortal.sandboxPortalUrl.value}/register`,
    resetPasswordUrl: `${individualPortal.sandboxPortalUrl.value}/password/reset`,
    onSuccessLogin: ({ data: { token } }) => $individual.login(token)
  },
  {
    title: 'Partner',
    type: 'PARTNER',
    domain: 'PARTNER',
    loggedIn: $partnerEmployee.loggedIn,
    user: {
      id: $partnerEmployee.user.id,
      name: $partnerEmployee.user.name,
      email: $partnerEmployee.user.email,
      avatar: $partnerEmployee.user.avatar
    },
    actions: [
      {
        icon: mdiAccountCircle,
        text: 'Profile',
        component: 'a',
        bindOptions: {
          target: '_blank',
          href: `${partnerPortal.sandboxPortalUrl.value}/profile`
        }
      },
      {
        icon: mdiPower,
        text: 'Logout',
        on: {
          click: () => $partnerEmployee.logout()
        }
      }
    ],
    resetPasswordUrl: `${partnerPortal.sandboxPortalUrl.value}/password/reset`,
    onSuccessLogin: ({ data: { token } }) => $partnerEmployee.login(token)
  },
  {
    title: 'Admin',
    type: 'ADMIN',
    domain: 'ADMIN',
    loggedIn: $payianoEmployee.loggedIn,
    user: {
      id: $payianoEmployee.user.id,
      name: $payianoEmployee.user.name,
      email: $payianoEmployee.user.email,
      avatar: $payianoEmployee.user.avatar
    },
    actions: [
      {
        icon: mdiAccountCircle,
        text: 'Profile',
        component: 'a',
        bindOptions: {
          target: '_blank',
          href: `${adminPortal.sandboxPortalUrl.value}/profile`
        }
      },
      {
        icon: mdiPower,
        text: 'Logout',
        on: {
          click: () => $payianoEmployee.logout()
        }
      }
    ],
    resetPasswordUrl: `${adminPortal.sandboxPortalUrl.value}/password/reset`,
    onSuccessLogin: ({ data: { token } }) => $payianoEmployee.login(token)
  }
])

const firstLoggedIn = computed(() =>
  accounts.value.find(({ loggedIn }) => loggedIn)
)

const buttonOptions = computed<IButton>(() => ({
  icon: mdiLogin,
  disabled: $login.showModal,
  on: {
    click: () => $login.openModal('INDIVIDUAL')
  }
}))
</script>
