<template>
  <HaMenu
    close-on-click
    :follower="{
      offset: lgAndDown ? 6 : 11
    }"
  >
    <template #activator="{ props: menuProps, on: menuOn }">
      <HaButton
        v-bind="{
          size: 38,
          circle: true,
          ...menuProps
        }"
        v-on="{
          ...menuOn
        }"
      >
        <HaAvatar
          size="38"
          :name="firstLoggedIn.user.name"
          :avatar="firstLoggedIn.user.avatar"
        />
      </HaButton>
    </template>

    <div class="bg-white min-w-[300px] divide-y divide-gray-200">
      <div v-for="(account, accountIndex) in accounts" :key="accountIndex">
        <div class="text-lg font-bold px-3 py-2 text-gray" @click.stop>
          {{ account.title }}
        </div>

        <div v-if="account.loggedIn" class="divide-y divide-gray-200">
          <div class="px-3 pb-3 flex items-start" @click.stop>
            <HaAvatar
              size="40"
              :name="account.user.name"
              :avatar="account.user.avatar"
            />

            <div class="ps-3">
              <div class="mb-0.5">
                {{ account.user.name }}
              </div>

              <div class="text-xs text-gray-600">
                <div class="mb-0.5">
                  {{ account.user.email }}
                </div>

                <div>
                  <span class="me-1">{{ $t('id') }}:</span>
                  <span class="font-bold">{{ account.user.id }}</span>
                </div>
              </div>
            </div>
          </div>

          <HaButton
            v-for="(action, actionIndex) in account.actions"
            :key="actionIndex"
            class="flex items-start"
            v-bind="{
              rounded: false,
              fullWidth: true,
              color: 'default',
              ...action
            }"
          />
        </div>
        <div v-else class="flex items-center justify-center pb-3">
          <HaButton
            size="xs"
            color="secondary"
            text="Login to Sandbox"
            :icon="{ size: 16, icon: mdiLogin }"
            :disabled="$login.showModal"
            @click="$login.openModal(account.type)"
          />
        </div>
      </div>
    </div>
  </HaMenu>
</template>

<script lang="ts" setup>
import { mdiLogin } from '@mdi/js'
import { HaAvatar, HaButton, HaMenu } from '@payiano/ha-atoms'
import { useDisplay } from '@payiano/ha-composables'
import { Auth } from '@/types'

const { $login } = useNuxtApp()
const { lgAndDown } = useDisplay()

defineProps<{
  accounts: Auth.IAccount[]
  firstLoggedIn: Auth.IAccount
}>()
</script>
